import { Market, useSetMobileDevice } from '@openware/opendax-web-sdk'
import AssetsTable from 'components/Assets/AssetsTable'
import { Banner, LandingMarketsWidget, LatestArticles, PoweredByBlock } from 'components/Landing'
import { POWERED_OPTIONS } from 'components/Landing/PoweredByBlock/PoweredByBlock.constants'
import { Layout } from 'components/Layout'
import { TickersList } from 'components/TickersList'
import { appTitle, DeviceType, extractCached, getDeviceTypeFromHeaders, getHeaderNavigation, translate } from 'helpers'
import { convertPlainText } from 'helpers/convertPlainText'
import { getSupabasePublicClient } from 'libs/SupabaseConnector'
import { GetServerSideProps } from 'next'
import Head from 'next/head'
import { useRouter } from 'next/router'
import React, { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { Article, Category } from 'types'
import { ASSETS_PER_DESKTOP_PAGE, ASSETS_PER_MOBILE_PAGE } from '../constants'
import { analytics } from '../helpers/analytics'

interface HomeProps {
  marketsList: Market[]
  articles: Article[]
  assetTableData: any
  categories: Category[]
  /**
   * Response from server for detecting mobile device
   */
  responseIsMobile?: boolean
}

const Home: React.FC<HomeProps> = ({
  marketsList,
  articles,
  assetTableData,
  categories,
  responseIsMobile,
}: HomeProps): JSX.Element => {
  const router = useRouter()
  const isMobile = useSetMobileDevice()
  const isTablet = useSetMobileDevice(false, 1025)

  useEffect(() => {
    analytics({ type: 'pageView', name: 'main_page_view' })
  }, [router.asPath])

  const mainContent = React.useMemo(() => {
    return (
      <div>
        <div>
          <TickersList />
        </div>
        <div className="flex mt-9 flex-col mb-6 lg:mb-[62px] lg:flex-row mx-auto 3xl:w-[1250px] 2xl:w-[1155px] xl:w-[921px] xl:justify-center">
          <div className="4xl:w-full 3xl:w-full 2xl:w-[735px] xl:w-[500px]">
            <AssetsTable
              assets={assetTableData.assets}
              start={assetTableData.start}
              end={assetTableData.end}
              total={assetTableData.total}
              lastPage={assetTableData.lastPage}
              rowsPerPage={assetTableData.rowsPerPage}
              isMobile={isMobile === undefined ? !!responseIsMobile : !!isMobile}
            />
          </div>
          <div className="3xl:max-w-[452px] 2xl:max-w-[390px] xl:max-w-[390px] lg:ml-[30px] lg:max-w-[452px]">
            <PoweredByBlock items={POWERED_OPTIONS} />
            <LatestArticles articles={articles} responseIsMobile={responseIsMobile} />
            {!isTablet && !isMobile && <Banner />}
            {isMobile && <Banner />}
            <LandingMarketsWidget />
            {isTablet && !isMobile && <Banner />}
          </div>
        </div>
      </div>
    )
  }, [isMobile, marketsList, isTablet, responseIsMobile, assetTableData, articles])

  const actionButtonProps = {
    text: <FormattedMessage id="header.button.wallet" />,
    link: 'https://wallet.yellow.com',
  }

  return (
    <>
      <Head>
        <link rel="canonical" href="/" />
        <meta name="description" content={translate('page.home.meta.description')} />
        <title>{appTitle(translate('page.home.title'))}</title>
        <meta property="og:url" content="/" />
        <meta property="og:title" content={appTitle(translate('page.home.title'))} />
        <meta property="og:description" content={translate('page.home.meta.description')} />
        <meta property="og:site_name" content="yellow.com" />
        <meta name="twitter:site" content="twitter.com/Yellow" />
        <meta name="twitter:creator" content="@Yellow" />
      </Head>
      <Layout
        actionButtonProps={actionButtonProps}
        headerOptions={{
          ...getHeaderNavigation(categories),
          responseIsMobile,
        }}
      >
        <div className="bg-main-background-color">{mainContent}</div>
      </Layout>
    </>
  )
}

export const getServerSideProps: GetServerSideProps = async (context: any) => {
  context.res.setHeader('Cache-Control', 'public, max-age=600, stale-while-revalidate=59')

  const isMobile = getDeviceTypeFromHeaders(context.req) != DeviceType.Desktop
  const rowsPerPage = isMobile ? ASSETS_PER_MOBILE_PAGE : ASSETS_PER_DESKTOP_PAGE
  const supabase = getSupabasePublicClient()

  const { categories, markets: marketsList } = await extractCached(context.req, context.res)

  const { data: assets, count } = await supabase
    .from('assets')
    .select('id, code, name, logo, price_change_24h, volume_24h, market_cap, price', {
      count: 'exact',
    })
    .order('market_cap', { ascending: false })
    .range(0, rowsPerPage - 1)

  const total = count ? count : 0
  const lastPage = Math.ceil(total / rowsPerPage)

  const { data: articles } = await supabase
    .from('articles')
    .select('image_url, content, description, asset_code, slug, title, state')
    .eq('state', 'published')
    .order('landing_position', { ascending: false })
    .limit(5)

  const articlesWithCutContent = articles?.map((article: Article) => {
    return {
      ...article,
      content: convertPlainText(article.content, true, 150),
    }
  })

  return {
    props: {
      marketsList,
      articles: articlesWithCutContent || [],
      categories,
      assetTableData: {
        assets,
        start: 0,
        end: rowsPerPage - 1,
        total,
        lastPage,
        rowsPerPage,
      },
      responseIsMobile: isMobile,
    },
  }
}

export default Home
